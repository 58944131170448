import sharedPolyfills from '@adsk/bim360-matrix-react-components/es/polyfills';

// The Promise polyfill is loaded in a sync manner since dynamic-import uses it, this is why it's loaded at the top.
// We don't want to override the native Promise in case the browser has one, this is why we use require here.
if (!window.Promise) {
  require('core-js/fn/promise');
}
const loadAllPolyfills = () => {
  const polyfills = [
    () => Promise.all(sharedPolyfills()),
  ];
  return Promise.all(polyfills.map((polyfill) => polyfill())).then(() => {
    NodeList.prototype[Symbol.iterator] = NodeList.prototype[Symbol.iterator] || Array.prototype[Symbol.iterator];
  });
};

export default loadAllPolyfills;
