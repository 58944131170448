import loadAllPolyfills from './polyfills_chunk';

const loadApplication = () => {
  // Why are we dynamically(!) loading the main in a different chunk and not just statically(!) import it?
  // If we would have statically imported this chunk it means that all the dependency tree on this file would have
  // been loaded.
  // If somewhere along the way there is a use of something we have a polyfill to it would have failed since the running
  // of all the static imported code would have run before the loading of the polyfills.
  // Therefore we have to dynamically(!) load it a a different chunk.
  import(/* webpackChunkName: "authentication_chunk" */ './authentication_chunk')
    .then((runAuth) => runAuth.default())
    .then((authenticationData) => {
      import(/* webpackChunkName: "main_chunk" */ './main_chunk')
        .then((runMain) => runMain.default(authenticationData))
        .catch((e) => {
          // TODO: How should we handle it?
          /* eslint-disable no-console */
          console.log('We should take care of this error in running the application..');
          console.log('Error: ', e);
          /* eslint-enable no-console */
        });
    })
    .catch((e) => {
      // TODO: How should we handle it?
      /* eslint-disable no-console */
      console.log('We should take care of this error in running the application..');
      console.log('Error: ', e);
      /* eslint-enable no-console */
    });
};

loadAllPolyfills()
  .then(loadApplication)
  .catch((e) => {
    // TODO: How should we handle it?
    /* eslint-disable no-console */
    console.log('We should take care of this error in polyfills..');
    console.log('Error: ', e);
    /* eslint-enable no-console */
    loadApplication();
  });

// Add support for HMR (Hot Module Replacement)
if (module.hot) {
  module.hot.accept();
}
